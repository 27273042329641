import React from "react";
import "bulma/css/bulma.min.css";
import "./App.css";
import { Redirect, Router } from "@reach/router";
import About from "./components/About";
import Corp from "./components/Corp";

function App() {
  return (
    <div className="App container is-desktop">
      <div className="column is-8-tablet is-offset-2-tablet">
        <Router>
          <Redirect from="/about/" to="/" noThrow />
          <About path="/" title="tachiba.jp" />
          <Corp path="/corp/" title="Corp - tachiba.jp" />
        </Router>
      </div>
    </div>
  );
}

export default App;
