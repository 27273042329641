import { Component } from "react";
import { RouteComponentProps } from "@reach/router";
import { EmptyProps } from "../types";

type PageTitleProps<T> = T & {
  title: string;
};

abstract class Page<
  P extends PageTitleProps<EmptyProps> = PageTitleProps<EmptyProps>,
  S = EmptyProps
> extends Component<RouteComponentProps<P>, S> {
  // https://github.com/reach/router/blob/e8325ae7a2d7be0a4e1de266ee15929f85fc2d94/website/src/App.js
  public componentDidMount(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.title = this.props.title;
  }
}

export default Page;
