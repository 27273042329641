import "./about.css";
import React from "react";
import History from "./History";
import Page from "./Page";

class About extends Page {
  render() {
    return (
      <div>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <figure className="image is-64x64">
                <img
                  className="is-rounded"
                  src="https://pbs.twimg.com/profile_images/778276551951462400/Zbr3EQSA.jpg"
                  alt="profile"
                />
              </figure>
            </div>
            <div className="level-item">
              <p className="full-name">Takashi Chiba</p>
            </div>
          </div>
        </div>
        <div className="content">
          <p>
            <a
              href="https://getpocket.com/@269d9p3bT6240glkr8Ac710A10gdTQ985d7o34c9b3B4dSdo77546N06Y1dQHU26"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pocket
            </a>
          </p>
          <p>
            <a
              href="https://booklog.jp/users/tachiba1207"
              target="_blank"
              rel="noopener noreferrer"
            >
              ブクログ @tachiba1207
            </a>
          </p>
          <p>
            <a
              href="https://github.com/tachiba"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github @tachiba
            </a>
          </p>
          <p>
            <a
              href="https://speakerdeck.com/tachiba"
              target="_blank"
              rel="noopener noreferrer"
            >
              Speaker Deck @tachiba
            </a>
          </p>
          <p>
            <a
              href="https://twitter.com/tachiba1207"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter @tachiba1207
            </a>
          </p>
          <p>
            <a
              href="https://note.mu/tachiba"
              target="_blank"
              rel="noopener noreferrer"
            >
              note @tachiba
            </a>
          </p>
          <p>
            <a
              href="https://medium.com/@tachiba"
              target="_blank"
              rel="noopener noreferrer"
            >
              medium @tachiba
            </a>
          </p>
          <p>
            <small>
              <a
                href="https://qiita.com/tachiba"
                target="_blank"
                rel="noopener noreferrer"
              >
                Qiita @tachiba
              </a>
              &nbsp;&nbsp;/&nbsp;&nbsp;
              <a
                href="http://memo.tachiba.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tumblr "気づき、学び、考え"
              </a>
              &nbsp;&nbsp;/&nbsp;&nbsp;
              <a
                href="http://tachiba.hatenablog.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hatena blog "Takashi Chiba"
              </a>
              &nbsp;&nbsp;/&nbsp;&nbsp;
              <a
                href="http://takashi.hatenablog.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hatena blog "寝る前にラーメン"
              </a>
            </small>
          </p>

          <History />
        </div>
      </div>
    );
  }
}

export default About;
