import React from "react";
import Page from "./Page";

class Corp extends Page {
  render() {
    return (
      <div className="content">
        <table className="table is-bordered">
          <tbody>
            <tr>
              <th>会社名</th>
              <td>合同会社tachiba</td>
            </tr>
            <tr>
              <th>事業内容</th>
              <td>
                <p>
                  <strong>ソフトウェア開発</strong>
                </p>
                <p>
                  ウェブ・モバイルアプリケーションの開発・設計やプロダクトマネージメント
                </p>
              </td>
            </tr>
            <tr>
              <th>設立</th>
              <td>2018年11月14日</td>
            </tr>
            <tr>
              <th>代表者</th>
              <td>千葉 高司</td>
            </tr>
            <tr>
              <th>本社所在地</th>
              <td>
                〒 160-0022 東京都新宿区新宿１丁目３６番２号新宿第七葉山ビル３階
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Corp;
