import React, { Component } from "react";
import "./history.css";
import { Link } from "@reach/router";
import { EmptyProps } from "../types";

interface HistoryState {
  hidden: boolean;
}

class History extends Component<Readonly<EmptyProps>, HistoryState> {
  constructor(props: Readonly<EmptyProps>) {
    super(props);

    this.state = {
      hidden: true,
    };

    this.show = this.show.bind(this);
  }

  show() {
    this.setState({ hidden: false });
  }

  render() {
    return (
      <div>
        <div className="buttons is-centered">
          <p className={!this.state.hidden ? "is-hidden" : ""}>
            <button
              type="button"
              className="button is-text"
              onClick={this.show}
            >
              略歴を展開 👾
            </button>
          </p>
        </div>
        <div className={this.state.hidden ? "is-hidden" : ""}>
          <h2>略歴 👾</h2>
          <h3>
            <Link to="/corp">合同会社tachiba (2018/11-)</Link>
          </h3>
          <h3>Wondershake (2010-2018/06)</h3>
          <ul>
            <li>
              2011年末 あれだけ「渡米するぞ！」と周りに話していたのに
              「ビザが取れませんでした！！」というのは今でこそ笑い話ですが、当時は大変でした。
              この時期にいつも通りかまってくれた方たちにはとても感謝をしています。
            </li>
            <li>
              <a
                href="http://fuwari.mobi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                2013年 TechCrunch Hackathon
                にチームで出場して表彰していただきました。
              </a>
            </li>
            <li>
              2013年頃
              アプリゲーム向けコミュニティサイトを単独で立ち上げ、売却してもらう。
            </li>
            <li>
              <a
                href="http://tachiba.hatenablog.jp/entry/2014/11/18/020626"
                target="_blank"
                rel="noopener noreferrer"
              >
                2014年 昨年に引き続き Hackathon
                にチームで出場して表彰していただきました。
              </a>
            </li>
            <li>2014年頃 メディア事業の立ち上げにみんなでフォーカス。</li>
            <li>2017年9月までCTO。その後にメディア事業部長。</li>
          </ul>
          <h3>CyberAgent (2011/04-2011/09)</h3>
          <h3>Apice (-2011)</h3>
          <ul>
            <li>
              友だちが立ち上げた学生団体から派生した会社で、受託を中心にやっていました。
            </li>
          </ul>
          <h3>PatentBureau (2006-2007)</h3>
          <ul>
            <li>
              千葉大学の近くにあったベンチャー企業にアルバイトとしてお世話になりました。
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default History;
